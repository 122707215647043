import React, { useState, useEffect } from 'react';
import { TidesStyle } from "../style/tidesStyle"

const OpeningTimes2 = () => {




      

  return (
    <div css={TidesStyle}>
      <table className="tide" id="tide">
        <tr>
          <th colSpan={2}>January 2025</th>
          <th colSpan={3}>Morning</th>
          <th colSpan={3}>Afternoon</th>
        </tr>
        <tr>
          <th>Date</th>
          <th>Day</th>
          <th>Open</th>
          <th>High</th>
          <th>Closed</th>
          <th>Open</th>
          <th>High</th>
          <th>Closed</th>
        </tr>

      

    
        <tr>
          <td>28</td>
          <td>Tuesday</td>
          <td className="tidegreen">02:29 </td>
          <td>05:29 </td>
          <td className="tidered">08:29 </td>
          <td className="tidegreen">14:55 </td>
          <td>17:55 </td>
          <td className="tidered">20:55</td>
        </tr>
        <tr>
          <td>29 </td>
          <td>Wednesday</td>
          <td className="tidegreen">03:15 </td>
          <td>06:15 </td>
          <td className="tidered">09:15 </td>
          <td className="tidegreen">15:40 </td>
          <td>18:40 </td>
          <td className="tidered">21:40</td>
        </tr>

        <tr>
          <td>30 </td>
          <td>Thursday</td>
          <td className="tidegreen">03:58 </td>
          <td>06:58 </td>
          <td className="tidered">09:58 </td>
          <td className="tidegreen">16:23 </td>
          <td>19:23 </td>
          <td className="tidered">22:23</td>
        </tr>
        <tr>
          <td>31 </td>
          <td>Friday </td>
          <td className="tidegreen">04:40 </td>
          <td>07:40 </td>
          <td className="tidered">10:40 </td>
          <td className="tidegreen">17:04 </td>
          <td>20:04 </td>
          <td className="tidered">23:04</td>
        </tr>
      </table>

      <table className="tide" id="tide">
        <thead>
          <tr>
            <th colspan="2">
            February 2025
            </th>
            <th colspan="3">Morning</th>
            <th colspan="3">Afternoon</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>Day</th>
            <th>Open</th>
            <th>High</th>
            <th>Closed</th>
            <th>Open</th>
            <th>High</th>
            <th>Closed</th>
          </tr>
        </thead>
        <tbody>
          <tr className="tideWeekend">
            <td>1</td>
            <td>Saturday</td>
            <td className="tidegreen">05:21</td>
            <td>08:21</td>
            <td className="tidered">11:21</td>
            <td className="tidegreen">17:45</td>
            <td>20:45</td>
            <td className="tidered">23:45</td>
          </tr>
          <tr className="tideWeekend">
            <td>2</td>
            <td>Sunday</td>
            <td className="tidegreen">06:01</td>
            <td>09:01</td>
            <td className="tidered">12:01</td>
            <td className="tidegreen">18:24</td>
            <td>21:24</td>
            <td className="tidered">00:24</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Monday</td>
            <td className="tidegreen">06:40</td>
            <td>09:40</td>
            <td className="tidered">12:40</td>
            <td className="tidegreen">19:02</td>
            <td>22:02</td>
            <td className="tidered">01:02</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Tuesday</td>
            <td className="tidegreen">07:20</td>
            <td>10:20</td>
            <td className="tidered">13:20</td>
            <td className="tidegreen">19:42</td>
            <td>22:42</td>
            <td className="tidered">01:42</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Wednesday</td>
            <td className="tidegreen">08:03</td>
            <td>11:03</td>
            <td className="tidered">14:03</td>
            <td className="tidegreen">20:28</td>
            <td>23:28</td>
            <td className="tidered">02:28</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Thursday</td>
            <td className="tidegreen">08:57</td>
            <td>11:57</td>
            <td className="tidered">14:57</td>
            <td colspan="3" className="tideyellow">View Next Morning</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Friday</td>
            <td className="tidegreen">21:29</td>
            <td>00:29</td>
            <td className="tidered">03:29</td>
            <td className="tidegreen">10:19</td>
            <td>13:19</td>
            <td className="tidered">16:19</td>
          </tr>
          <tr className="tideWeekend">
            <td>8</td>
            <td>Saturday</td>
            <td className="tidegreen">23:03</td>
            <td>02:03</td>
            <td className="tidered">05:03</td>
            <td className="tidegreen">12:03</td>
            <td>15:03</td>
            <td className="tidered">18:03</td>
          </tr>
          <tr className="tideWeekend">
            <td>9</td>
            <td>Sunday</td>
            <td className="tidegreen">00:39</td>
            <td>03:39</td>
            <td className="tidered">06:39</td>
            <td className="tidegreen">13:27</td>
            <td>16:27</td>
            <td className="tidered">19:27</td>
          </tr>
          <tr>
            <td>10</td>
            <td>Monday</td>
            <td className="tidegreen">01:48</td>
            <td>04:48</td>
            <td className="tidered">07:48</td>
            <td className="tidegreen">14:26</td>
            <td>17:26</td>
            <td className="tidered">20:26</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Tuesday</td>
            <td className="tidegreen">02:40</td>
            <td>05:40</td>
            <td className="tidered">08:40</td>
            <td className="tidegreen">15:11</td>
            <td>18:11</td>
            <td className="tidered">21:11</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Wednesday</td>
            <td className="tidegreen">03:24</td>
            <td>06:24</td>
            <td className="tidered">09:24</td>
            <td className="tidegreen">15:51</td>
            <td>18:51</td>
            <td className="tidered">21:51</td>
          </tr>
          <tr>
            <td>13</td>
            <td>Thursday</td>
            <td className="tidegreen">04:02</td>
            <td>07:02</td>
            <td className="tidered">10:02</td>
            <td className="tidegreen">16:27</td>
            <td>19:27</td>
            <td className="tidered">22:27</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Friday</td>
            <td className="tidegreen">04:37</td>
            <td>07:37</td>
            <td className="tidered">10:37</td>
            <td className="tidegreen">16:59</td>
            <td>19:59</td>
            <td className="tidered">22:59</td>
          </tr>
          <tr className="tideWeekend">
            <td>15</td>
            <td>Saturday</td>
            <td className="tidegreen">05:08</td>
            <td>08:08</td>
            <td className="tidered">11:08</td>
            <td className="tidegreen">17:27</td>
            <td>20:27</td>
            <td className="tidered">23:27</td>
          </tr>
          <tr className="tideWeekend">
            <td>16</td>
            <td>Sunday</td>
            <td className="tidegreen">05:36</td>
            <td>08:36</td>
            <td className="tidered">11:36</td>
            <td className="tidegreen">17:54</td>
            <td>20:54</td>
            <td className="tidered">23:54</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Monday</td>
            <td className="tidegreen">06:02</td>
            <td>09:02</td>
            <td className="tidered">12:02</td>
            <td className="tidegreen">18:18</td>
            <td>21:18</td>
            <td className="tidered">00:18</td>
          </tr>
          <tr>
            <td>18</td>
            <td>Tuesday</td>
            <td className="tidegreen">06:27</td>
            <td>09:27</td>
            <td className="tidered">12:27</td>
            <td className="tidegreen">18:41</td>
            <td>21:41</td>
            <td className="tidered">00:41</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Wednesday</td>
            <td className="tidegreen">06:51</td>
            <td>09:51</td>
            <td className="tidered">12:51</td>
            <td className="tidegreen">19:06</td>
            <td>22:06</td>
            <td className="tidered">01:06</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Thursday</td>
            <td className="tidegreen">07:19</td>
            <td>10:19</td>
            <td className="tidered">13:19</td>
            <td className="tidegreen">19:37</td>
            <td>22:37</td>
            <td className="tidered">01:37</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Friday</td>
            <td className="tidegreen">08:00</td>
            <td>11:00</td>
            <td className="tidered">14:00</td>
            <td className="tidegreen">20:33</td>
            <td>23:33</td>
            <td className="tidered">02:33</td>
          </tr>
          <tr className="tideWeekend">
            <td>22</td>
            <td>Saturday</td>
            <td colspan="3" className="tideyellow">View Last Evening</td>
            <td className="tidegreen">09:21</td>
            <td >12:21</td>
            <td className="tidered">15:21</td>
          </tr>
          <tr className="tideWeekend">
            <td>23</td>
            <td>Sunday</td>
            <td className="tidegreen">22:30</td>
            <td>01:30</td>
            <td className="tidered">04:30</td>
            <td className="tidegreen">11:31</td>
            <td>14:31</td>
            <td className="tidered">17:31</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Monday</td>
            <td className="tidegreen">00:17</td>
            <td>03:17</td>
            <td className="tidered">06:17</td>
            <td className="tidegreen">12:54</td>
            <td>15:54</td>
            <td className="tidered">18:54</td>
          </tr>
          <tr>
            <td>25</td>
            <td>Tuesday</td>
            <td className="tidegreen">01:20</td>
            <td>04:20</td>
            <td className="tidered">07:20</td>
            <td className="tidegreen">13:50</td>
            <td>16:50</td>
            <td className="tidered">19:50</td>
          </tr>
          <tr>
            <td>26</td>
            <td>Wednesday</td>
            <td className="tidegreen">02:11</td>
            <td>05:11</td>
            <td className="tidered">08:11</td>
            <td className="tidegreen">14:38</td>
            <td>17:38</td>
            <td className="tidered">20:38</td>
          </tr>
          <tr>
            <td>27</td>
            <td>Thursday</td>
            <td className="tidegreen">02:58</td>
            <td>05:58</td>
            <td className="tidered">08:58</td>
            <td className="tidegreen">15:23</td>
            <td>18:23</td>
            <td className="tidered">21:23</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Friday</td>
            <td className="tidegreen">03:42</td>
            <td>06:42</td>
            <td className="tidered">09:42</td>
            <td className="tidegreen">16:05</td>
            <td>19:05</td>
            <td className="tidered">22:05</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default OpeningTimes2;
